<template>
  <div class="animated fadeIn container">
    <h1>수신번호를 직접 입력하거나<br />파일로 업로드하여 문자를 발송하세요</h1>
    <h2 class="mt-2 color-skyblue">{{ availableSendCount }}건 발송가능</h2>
    <div class="row mt-4">
      <div class="col-4">
        <div class="phone-wrap">
          <textarea
            class="inner-text"
            id="message"
            name="body"
            rows="4"
            v-model="message"
            placeholder="메시지를 입력해주세요"
            v-on:input="updateMessage"
            required
            @keydown.enter.shift.exact.prevent="createSmsRequest"
          ></textarea>
          <span class="color-skyblue inner-bytes">{{ contentBytes }}/70</span>
        </div>
      </div>
      <div class="col-8">
        <table class="border-table">
          <tr v-show="user ? user.providerId == 3 : false">
            <td width="160px">발신번호</td>
            <td style="display:flex;flex-flow:row;align-items: center;justify-content:center;">
              <select id="sender-number-select" style="flex-flow:1" class="form-control" v-model="senderNumber">
                <option value="" v-if="senders.length === 0">발신번호를 등록해주세요</option>
                <option v-for="sender in senders" :value="sender.phoneNumber" :key="sender.id">
                  {{ sender.phoneNumber | phoneFormat}}
                </option>
              </select>
              <button class="btn-primary btn-rounded-small ml-2" @click="clickManageSender" style="min-width:100px">
                번호관리
              </button>
              <button class="btn-primary btn-rounded-small ml-2" @click="clickRegisterSender" style="min-width:100px">
                번호등록
              </button>
            </td>
          </tr>
          <tr>
            <td width="160px">수신번호</td>
            <td>
              <textarea
                v-model="recipients"
                :style="recipientsStyle"
                class="recipient-number"
                rows="4"
                required
                @focus="focusRecipientsInput"
                @blur="blurfRecipientsInput"
                @keydown.enter.shift.exact.prevent="createSmsRequest"
              ></textarea>
            </td>
          </tr>
          <tr>
            <td>고정 수신번호</td>
            <td>
              <b-form-checkbox type="checkbox" v-model="useFixNumber">고정 수신번호 사용</b-form-checkbox>
              <textarea
                v-show="useFixNumber"
                v-model="fixRecipients"
                :style="fixRecipientsStyle"
                class="mt-2 recipient-number"
                rows="4"
                required
                @focus="focusRecipientsInput"
                @blur="blurfRecipientsInput"
                @keydown.enter.shift.exact.prevent="createSmsRequest"
              ></textarea>
            </td>
          </tr>
          <tr>
            <td>파일로 업로드</td>
            <td>
              <input
                ref="excel"
                type="file"
                id="excel"
                name="excel"
                accept=".xlsx,.txt,.csv"
                @change="handleFileChange"
              />
              <span v-if="files && files.length > 0" style="line-height:29px">{{ files[0].name }}</span>
              <span v-else style="line-height:29px">선택된 파일 없음</span>
              <button
                class="btn-primary float-right btn-rounded-small"
                @click="clickCustomFileBtn"
              >
                파일 선택
              </button>
            </td>
          </tr>
          <tr>
            <td>예약 발송</td>
            <td>
              <b-form-checkbox type="checkbox" v-model="useReservation">예약 발송 사용</b-form-checkbox>
              <datetime
                v-show="useReservation"
                class="reservation-datetime"
                type="datetime"
                v-model="reservationDate"
                :min-datetime="minReservationDate"
                :max-datetime="maxReservationDate"
                :minute-step="5"
                :phrases="{ ok: '확인', cancel: '취소' }"
                placeholder="날짜를 선택해주세요"
                use12-hour
              />
            </td>
          </tr>
        </table>

        <b-btn
          ref="sendBtn"
          variant="primary"
          class="float-left btn-primary send-btn"
          @click="createSmsRequest"
          :disabled="isLoading"
        >
          <span v-if="!isLoading">발송</span>
          <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
        </b-btn>
        <div class="mt-2">
          <a href="/assets/sample.txt" style="color:#a0a0a0;text-decoration:underline;float:right" download=""
            >메모장 샘플</a
          >
          <a
            href="/assets/sample.xlsx"
            style="color:#a0a0a0;text-decoration:underline;float:right;margin-right:20px"
            download=""
            >엑셀 샘플</a
          >
        </div>
        <div>
          <div id="chart-wrap" v-show="this.user.providerId === 1">
            <div id="container-speed" class="chart-container"></div>
            <p id="server-status-sub-msg">불러오는 중</p>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="senderRegisterModal" title="발신번호 등록" hide-footer="true" centered>
      <div style="display:flex;flex-flow:row;">
        <input placeholder="전화번호" class="form-control" style="flex-grow:1" 
        v-model="generateSenderNumber" 
        @keyup="generateSenderNumberKeyUp"/>
        <button
          class="ml-1 btn-primary btn btn-small"
          @click="clickSendAuthCode"
          style="min-width:100px"
          :disabled="senderAuthCodeSecs < 120"
        >
          코드 전송
        </button>
      </div>
      <div>
        <input
          id="senderAuthCodeInput"
          v-model="senderAuthCode"
          type="text"
          class="form-control mt-2"
          placeholder="문자로 받은 4자리 코드를 입력해주세요"
          style="width:100%"
        />
        <span class="sender-auth-code-time" v-show="senderAuthCodeSecs < 120">
          {{senderAuthCodeSecs | timeFormat}}
        <span>
      </div>
      <div>
        <button class="mt-2 btn btn-primary w100" @click="clickCheckSenderAuthCode()" style="width:100%">
          발신번호 등록
        </button>
      </div>
    </b-modal>
    <b-modal ref="senderManageModal" title="발신번호 관리" hide-footer="true" centered>
      <ul class="sender-number-list">
        <li v-for="item in senders" :key="item.id">
          <div>
            {{ item.phoneNumber | phoneFormat}}
            <span class="float-right">{{ item.sentCount | numberFormat }}건 발송</span>
          </div>
          <button class="btn btn-danger btn-small ml-2" @click="deleteSender(item.id)">삭제</button>
        </li>
      </ul>
    </b-modal>
    <b-modal ref="sendCompleteModal" title="메시지 전송 완료" ok-only ok-title="확인" centered>
      발송: <span class="color-skyblue">{{ sendResponse.totalCount }}</span> 건<br />
    </b-modal>
  </div>
</template>
<script>
import SmsService from '@/services/SmsService'
import ContactService from '@/services/ContactService'
import SenderService from '@/services/SenderService'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'
import { Datetime } from 'vue-datetime'
import { Settings } from 'luxon'

Settings.defaultLocale = 'kr'

export default {
  name: 'SmsSend',
  data() {
    return {
      generateSenderNumber: '',
      senderAuthCode: '',
      senders: [],
      senderNumber: '',
      senderAuthCodeSecs: 120,
      senderAuthCodeTimer: null,
      chartSpeed: null,
      availableSendCount: 0,
      recipientsPlaceholder: '전화번호1\n전화번호2\n전화번호3\n...',
      recipients: '전화번호1\n전화번호2\n전화번호3\n...',
      recipientsStyle: {
        color: '#999',
      },
      fixRecipients: '전화번호1\n전화번호2\n전화번호3\n...',
      fixRecipientsStyle: {
        color: '#999',
      },
      message: '',
      isLoading: false,
      loaderStyle: {
        color: 'rgba(255, 255, 255, 0.7)',
        size: '8px',
      },
      files: null,
      sendResponse: {
        totalCount: '',
        paidMoney: '',
        holdMoney: '',
      },
      gaugeOptions: {
        chart: {
          type: 'solidgauge',
        },
        title: null,
        pane: {
          center: ['50%', '85%'],
          size: '100%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (window.Highcharts.theme && window.Highcharts.theme.background2) || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
          },
        },

        tooltip: {
          enabled: false,
        },

        // the value axis
        yAxis: {
          stops: [
            [0.2, '#55BF3B'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.8, '#DF5353'], // red
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: -70,
          },
          labels: {
            y: 16,
          },
        },

        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
      },
      useReservation: false,
      useFixNumber: false,
      reservationDate: null,
      minReservationDate: null,
      maxReservationDate: null,
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    this.getUserInfo()
    this.createStatusChart()
    this.getContactList()
    this.getSenderList()
  },
  destroyed() {
    clearInterval(this.senderAuthCodeTimer)
  },
  components: {
    PulseLoader,
    Datetime,
  },
  created() {
    this.minReservationDate = new Date().toISOString()

    var maxDate = new Date()
    maxDate.setDate(maxDate.getDate() + 7)
    this.maxReservationDate = maxDate.toISOString()
  },
  methods: {
    clickManageSender() {
      this.$refs.senderManageModal.show()
    },
    clickRegisterSender() {
      this.senderAuthCodeSecs = 120;
      this.generateSenderNumber = ''
      this.senderAuthCode = ''
      this.$refs.senderRegisterModal.show()
    },
    clickSendAuthCode() {
      const t = this
      SenderService.create({
        phoneNumber: this.generateSenderNumber,
      }).then(function(response) {
        if (response.status !== 200) {
          return
        }
        clearInterval(t.senderAuthCodeTimer)
        t.senderAuthCodeTimer = setInterval(function() {
          if(t.senderAuthCodeSecs < 0) {
            t.senderAuthCodeSecs = 120;
            clearInterval(t.senderAuthCodeTimer)
            return;
          }

          t.senderAuthCodeSecs--;
        }, 1000)
        document.getElementById('senderAuthCodeInput').focus()
      })
    },
    async clickCheckSenderAuthCode() {
      const response = await SenderService.checkAuth(this.generateSenderNumber, this.senderAuthCode)
      if (response.status !== 200) {
        return
      }
      alert('등록되었습니다')
      clearInterval(this.senderAuthCodeTimer)
      this.$refs.senderRegisterModal.hide()
      this.getSenderList();
    },
    async getSenderList() {
      var response = await SenderService.list()
      if (response.status !== 200) {
        return
      }
      this.senders = response.data.list || []
      var senderNumbers = []
      for (var sender of this.senders) {
        senderNumbers.push(sender.phoneNumber)
      }
      this.senderNumbers = senderNumbers

      if(senderNumbers.length > 0) {
        this.senderNumber = senderNumbers[0];
      }
      else {
        this.senderNumber = "";
      }
    },
    async deleteSender(id) {
      if (!confirm('해당 발신번호를 삭제하시겠습니까?')) {
        return
      }

      var response = await SenderService.delete(id)
      if (response.status !== 200) {
        return
      }
      this.getSenderList()
    },
    generateSenderNumberKeyUp() {
      var number = this.generateSenderNumber.replace(/[^0-9]/g, "");
      var phone = ""
      if(number.length < 4) {
          phone = number;
      } else if(number.length < 7) {
          phone += number.substr(0, 3);
          phone += "-";
          phone += number.substr(3);
      } else if(number.length < 11) {
          phone += number.substr(0, 3);
          phone += "-";
          phone += number.substr(3, 3);
          phone += "-";
          phone += number.substr(6);
      } else {
          phone += number.substr(0, 3);
          phone += "-";
          phone += number.substr(3, 4);
          phone += "-";
          phone += number.substr(7);
      }
      this.generateSenderNumber = phone;
    },
    parseRecipient(recipients) {
      recipients = recipients.trim()
      recipients = recipients.replace(/\n\n+/g, '\n')
      recipients = recipients.replace(/[ \t\r]+/g, '')
      recipients = recipients.replace(/\n/g, ',')

      return recipients
    },
    createSmsRequest() {
      var recipients = ''
      if (this.recipients != this.recipientsPlaceholder) {
        recipients = this.parseRecipient(this.recipients)
      }

      var fixRecipients = ''
      if (this.useFixNumber && this.fixRecipients != this.recipientsPlaceholder) {
        fixRecipients = this.parseRecipient(this.fixRecipients)
      }

			if (this.contentBytes <= 0) {
        alert('메시지를 입력해주세요')
        return
      } else if (!this.user.useLong && this.contentBytes > 70) {
        alert('메시지는 70자까지만 가능합니다')
        return
      }
      
      if(this.user.useLong && this.contentBytes >= 70) {
        if(!confirm('70자마다 ' + (parseInt(this.message.length/70)+1)+'배가 과금됩니다. 전송하시겠습니까?')) {
          return;
        }
      }

      var formData = new FormData()
      formData.append('message', this.message)
      if (recipients.length > 0) {
        formData.append('recipients', recipients)
      }

      if (fixRecipients.length > 0) {
        formData.append('fixRecipients', fixRecipients)
      }

      if (this.useReservation) {
        if (this.reservationDate.length == 0) {
          alert('예약 시간을 입력해주세요')
          return
        }
        formData.append('reservationDate', this.reservationDate)
      }
      var files = this.$refs.excel.files
      if (files && files.length > 0) {
        formData.append('excel', files[0])
      }

      if (this.user.providerId == 3) {
        if (this.senderNumber.length === 0) {
          alert('발신번호를 선택해주세요')
          return
        } else {
          formData.append('sender', this.senderNumber)
        }
      }
      
      if (this.isLoading) {
        return
      }
      this.isLoading = true

      const t = this
      SmsService.createSmsRequest(formData)
        .then(function(response) {
          if (response.status !== 200) {
            return
          }
          t.sendResponse = response.data
          t.getUserInfo()
          t.clear()
          t.$refs.sendCompleteModal.show()
          setTimeout(function() {
            t.isLoading = false
          }, 200)
        })
        .catch(function() {
          t.isLoading = false
        })
    },
    handleFileChange() {
      this.files = this.$refs.excel.files
    },
    clickCustomFileBtn() {
      document.getElementById('excel').click()
    },
    enterRecipientsInput(e) {
      console.log(e)
    },
    focusRecipientsInput(e) {
      const t = e.target
      t.style.color = '#333'
      if (this.recipientsPlaceholder == t.value) {
        t.value = ''
      }
    },
    blurfRecipientsInput(e) {
      const t = e.target
      if (t.value.length == 0) {
        t.value = this.recipientsPlaceholder
        t.style.color = '#999'
      }
    },
    updateMessage(e) {
      this.message = e.target.value
    },
    clear() {
      this.recipients = this.recipientsPlaceholder
      this.recipientsStyle = {
        color: '#999',
      }
      this.files = null
      this.$refs.excel.value = null
    },
    createStatusChart() {
      const Highcharts = window.Highcharts
      this.chartSpeed = Highcharts.chart(
        'container-speed',
        Highcharts.merge(this.gaugeOptions, {
          yAxis: {
            min: 0,
            max: 100,
            title: {
              text: '문자야 발송현황',
            },
          },

          credits: {
            enabled: false,
          },

          series: [
            {
              name: 'WaitCount',
              data: [0],
              dataLabels: {
                format:
                  '<div style="text-align:center"><span style="font-size:25px;color:' +
                  ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') +
                  '">{y}</span><br/>' +
                  '<span style="font-size:12px;color:#333" id="server-status-msg">-</span></div>',
              },
              tooltip: {
                valueSuffix: '%',
              },
            },
          ],
        })
      )
    },
    async updateStatus(status) {
      if (!status) {
        return
      }

      const { waitCount } = status

      var percent = (waitCount / 25000) * 100
      percent = percent < 0 ? 0 : percent
      percent = percent > 100 ? 100 : percent
      percent = parseInt(percent)

      var point = this.chartSpeed.series[0].points[0]
      point.update(percent)

      var statusText = ''
      var statusSubMsg = ''
      if (percent < 20) {
        statusText = '원활'
        statusSubMsg = '현재 빠른 발송이 가능합니다'
      }
      if (percent < 30) {
        statusText = '원활'
        statusSubMsg = '현재 원활한 발송이 가능합니다'
      } else if (percent < 70) {
        statusText = '대기'
        statusSubMsg = '현재 발송이 약간 느릴 수 있습니다'
      } else {
        statusText = '느림'
        statusSubMsg = '현재 발송요청이 밀려있어 발송이 느립니다'
      }

      document.getElementById('server-status-msg').innerText = statusText
      document.getElementById('server-status-sub-msg').innerText = statusSubMsg
    },
    async getContactList() {
      try {
        const response = await ContactService.list()
        const list = response.data.list

        if (list.length > 0) {
          this.fixRecipients = list[0].recipients.replace(/,/g, '\n')
          this.fixRecipientsStyle = { color: '#333' }
        }
      } catch (e) {
        // Do nothing
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    contentBytes: function() {
      var message = this.message.replace(/(\r\n|\r|\n)/g, '  ')
      return message.length
    },
  },
  watch: {
    user() {
      this.availableSendCount = parseInt(this.user.money / this.user.smsFee)
    },
    '$store.state.status'() {
      this.updateStatus(this.$store.state.status)
    },
    isLoading() {
      this.$refs.sendBtn.disabled = this.isLoading
    },
    contacts() {},
    senders() {},
    senderNumber() {},
    senderAuthCodeSecs() {}
  },
}
</script>

<style scoped lang="scss">
.border-table {
  border-bottom: 1px solid #ebebeb;
}

#sms-send-loader {
  display: block;
  margin: 20px auto;
}

.phone-wrap {
  position: relative;
  width: 256px;
  height: 523px;
  background: url('/img/phone.jpg') center no-repeat;
}

.phone-wrap .inner-text {
  position: absolute;
  top: 65px;
  left: 18px;
  width: 220px;
  bottom: 65px;
  padding: 20px 15px;
  color: #000;
  line-height: 20px;
  font-size: 15px;
  font-weight: 300;
  outline: none;
  letter-spacing: 1;
}

.phone-wrap .inner-bytes {
  position: absolute;
  right: 33px;
  bottom: 73px;
}

.recipient-number {
  border: none;
  outline: none;
  width: 100%;
  height: 100px;
}

#excel {
  display: none;
}

.send-btn {
  display: block;
  margin-top: 60px;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 110px;
  height: 40px;
  border-radius: 0.25rem;
}

#chart-wrap {
  margin-top: 50px;
  position: relative;
  width: 240px;
  height: 250px;
  float: right;
}

#server-status-sub-msg {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  white-space: nowrap;
  letter-spacing: -0.2px;
}

#container-speed {
  float: left;
  width: 240px;
  height: 240px;
}
</style>
<style>
.reservation-datetime {
  margin-top: 10px;
  width: 190px;
}

.vdatetime-input {
  padding-left: 10px;
  width: 100%;
  text-align: left;
}

.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background: #039bfa;
}

.vdatetime-year-picker__item--selected,
.vdatetime-time-picker__item--selected,
.vdatetime-popup__actions__button {
  color: #039bfa;
}

.sender-number-list > li {
  margin-bottom: 10px;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.sender-number-list > li > div:first-of-type {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-rounded-small {
  padding:3px 10px;
  border-radius:0.25rem;
}


.sender-auth-code-time {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  color: #edc028;
  opacity: 0.8;
}
</style>
